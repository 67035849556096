import styled from 'styled-components';

import { metrics } from '@/theme';

export const Container = styled.div`
  width: 50%;
  padding: 0 20px;
  @media (max-width: ${metrics.mobile}px) {
    width: 100%;
  }
`;
