import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Section = ({ children }) => <Container>{children}</Container>;

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default Section;
