/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Section from '@/components/Section';
import Title from '@/components/Title';
import ExternalLink from '@/components/ExternalLink';

const Imprint = () => {
  const { contentsJson } = useStaticQuery(
    graphql`
      query imprintQuery {
        contentsJson(imprint: { id: { eq: "imprint" } }) {
          imprint {
            title
            items {
              name
              target
              url
            }
          }
        }
      }
    `
  );

  const { title, items } = contentsJson.imprint;

  return (
    <Section>
      <Title>{title}</Title>
      {items.map(({ name, target, url }, i) => (
        <Fragment key={i}>
          <ExternalLink href={url} target={target} rel="noopener noreferrer">
            {name.map((txt, j) => (
              <Fragment key={j}>
                <span>{txt}</span>
                <br />
              </Fragment>
            ))}
          </ExternalLink>
          <br />
        </Fragment>
      ))}
    </Section>
  );
};

export default Imprint;
export { default as Head } from '@/components/Head';
