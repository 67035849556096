import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { getPageTitleByUri } from './utils';

const Head = ({ location }) => {
  const { pathname } = location;
  const { site, file } = useStaticQuery(
    graphql`
      query metaDataQuery {
        site {
          siteMetadata {
            type
            title
            keywords
            description
            author
          }
        }
        file(relativePath: { eq: "openGraph.png" }) {
          publicURL
        }
      }
    `
  );

  const subTitle = useMemo(() => getPageTitleByUri(pathname), [pathname]);
  const { type, title, keywords, description, author } = site.siteMetadata;
  const openGraph = file.publicURL;
  const baseUrl = process.env.GATSBY_URL;
  const procEnv = process.env.GATSBY_PROCESS;
  const metaTitle = useMemo(() => (subTitle ? `${title} | ${subTitle}` : title), [subTitle, title]);

  return (
    <>
      <title>{metaTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={(keywords || []).join(', ')} />
      <meta name="robots" content={procEnv === 'production' ? 'index, follow' : 'noindex, nofollow'} />
      <meta name="revisit-after" content="100 days" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={metaTitle} />
      <meta property="og:url" content={baseUrl} />
      <meta property="twitter:site" content={baseUrl} />
      <meta property="og:type" content={type} />
      {openGraph && <meta property="og:image" content={baseUrl + openGraph} />}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:creator" content={author} />
      <meta property="twitter:url" content={baseUrl} />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={description} />
      {openGraph && <meta property="twitter:image" content={baseUrl + openGraph} />}
    </>
  );
};

Head.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Head;
