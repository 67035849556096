import styled from 'styled-components';

import { fonts, metrics } from '@/theme';

export default styled.h1`
  ${fonts.title}
  margin: 100px 0 60px 0;
  @media (max-width: ${metrics.mobile}px) {
    margin-bottom: 25px;
  }
`;
